var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "flex flex-col items-center justify-center h-screen pb-12 mx-auto px-4 sm:px-8 bg-gray-200",
      attrs: { id: "reset-password" },
    },
    [
      _c(
        "div",
        { staticClass: "mb-2 logo-login" },
        [
          _vm.customDomain
            ? _c("custom-brand-img", {
                attrs: {
                  type: _vm.customImgTypes.BG_LIGHT,
                  "img-class": "custom-brand",
                },
              })
            : _c("sun-media-logo-dark", { staticClass: "p-8" }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "w-auto sm:w-full sm:max-w-3xl" },
        [
          _vm.showErrorAlert
            ? _c("asterix-error-alert", {
                attrs: {
                  "text-title": "Error",
                  "text-description": _vm.errorMessage,
                },
              })
            : _vm._e(),
          _c(
            "div",
            {
              staticClass:
                "flex flex-row justify-content-between bg-white shadow-lg rounded-lg text-left",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "flex flex-1 py-4 inner-reset pb-10 pt-8 px-4 sm:px-10",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex-1 shadow-lg rounded-lg border border-gray-200 pt-2 pb-6 px-4 sm:pr-10 sm:pl-8",
                    },
                    [
                      _c("sun-form", { on: { submit: _vm.sendForm } }, [
                        _c(
                          "div",
                          { staticClass: "mb-4 my-2" },
                          [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "mb-6 text-2xl sm:text-3xl font-bold pr-8 sm:pr-0 text-gray-700",
                              },
                              [_vm._v("Reset Password")]
                            ),
                            _vm.isResetPassword
                              ? _c(
                                  "div",
                                  [
                                    _c("asterix-new-password-input", {
                                      attrs: {
                                        required: "required",
                                        "auto-validate": "",
                                        column: "",
                                      },
                                      model: {
                                        value: _vm.newPassword,
                                        callback: function ($$v) {
                                          _vm.newPassword = $$v
                                        },
                                        expression: "newPassword",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : [
                                  _c(
                                    "sun-label-group",
                                    {
                                      staticClass: "text-xs",
                                      attrs: {
                                        text: "Email",
                                        "is-required": false,
                                      },
                                    },
                                    [
                                      _c("asterix-input", {
                                        attrs: {
                                          type: "email",
                                          name: "email",
                                          "validate-on-blur": "",
                                          placeholder: "Email",
                                          required: "required",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                          ],
                          2
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "flex items-center justify-between mt-6",
                          },
                          [
                            _c(
                              "sun-button",
                              {
                                staticClass: "text-sm font-light mr-8",
                                attrs: {
                                  type: "submit",
                                  loading: _vm.isLoading,
                                  variant: "pill",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.isResetPassword
                                        ? "Save"
                                        : "Send email"
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "router-link",
                              {
                                staticClass:
                                  "text-sm font-medium text-yellow-500 hover:text-yellow-700",
                                attrs: { to: { name: "login" } },
                              },
                              [_vm._v(" Back to Login ")]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ]
              ),
              _vm._m(0),
            ]
          ),
          _c("p", { staticClass: "text-center text-gray-500 text-xs mt-6" }, [
            _vm._v("©" + _vm._s(_vm.year) + " SunMedia All rights reserved."),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "hidden sm:flex flex-1 inner-reset bg-gray-custom rounded-r-lg flex items-center px-6 py-6 justify-center",
      },
      [
        _c("img", {
          staticClass: "w-full relative img-reset",
          attrs: {
            alt: "SunMedia background decoration",
            src: require("@/assets/svg/imagen-reset.svg"),
          },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }