<template>
  <div
    id="reset-password"
    class="flex flex-col items-center justify-center h-screen pb-12 mx-auto px-4 sm:px-8 bg-gray-200"
  >
    <div class="mb-2 logo-login">
      <custom-brand-img v-if="customDomain" :type="customImgTypes.BG_LIGHT" img-class="custom-brand" />
      <sun-media-logo-dark v-else class="p-8" />
    </div>
    <div class="w-auto sm:w-full sm:max-w-3xl">
      <asterix-error-alert v-if="showErrorAlert" text-title="Error" :text-description="errorMessage" />
      <!-- Form -->
      <div class="flex flex-row justify-content-between bg-white shadow-lg rounded-lg text-left">
        <div class="flex flex-1 py-4 inner-reset pb-10 pt-8 px-4 sm:px-10">
          <div class="flex-1 shadow-lg rounded-lg border border-gray-200 pt-2 pb-6 px-4 sm:pr-10 sm:pl-8">
            <sun-form @submit="sendForm">
              <div class="mb-4 my-2">
                <p class="mb-6 text-2xl sm:text-3xl font-bold pr-8 sm:pr-0 text-gray-700">Reset Password</p>
                <div v-if="isResetPassword">
                  <asterix-new-password-input v-model="newPassword" required="required" auto-validate column />
                </div>
                <template v-else>
                  <sun-label-group text="Email" class="text-xs" :is-required="false">
                    <asterix-input type="email" name="email" validate-on-blur placeholder="Email" required="required" />
                  </sun-label-group>
                </template>
              </div>
              <div class="flex items-center justify-between mt-6">
                <sun-button type="submit" :loading="isLoading" variant="pill" class="text-sm font-light mr-8">
                  {{ isResetPassword ? 'Save' : 'Send email' }}
                </sun-button>
                <router-link :to="{ name: 'login' }" class="text-sm font-medium text-yellow-500 hover:text-yellow-700">
                  Back to Login
                </router-link>
              </div>
            </sun-form>
          </div>
        </div>
        <div
          class="hidden sm:flex flex-1 inner-reset bg-gray-custom rounded-r-lg flex items-center px-6 py-6 justify-center"
        >
          <img
            alt="SunMedia background decoration"
            src="@/assets/svg/imagen-reset.svg"
            class="w-full relative img-reset"
          />
        </div>
      </div>
      <p class="text-center text-gray-500 text-xs mt-6">&copy;{{ year }} SunMedia All rights reserved.</p>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import SunMediaLogoDark from '@/components/icons/SunMediaLogoDark';
import { YEAR } from '@/utils/dateTime/year';
import { resetPasswordAndSendEmail, resetPassword } from '@/services/modules/Auth';
import { ERRORS } from '@/i18n/forms/errors';
import { SUCCESS } from '@/i18n/forms/success';
import { queryParamsMixin } from '@/mixins/common/queryParamsMixin';
import AsterixNewPasswordInput from '@/components/atoms/AsterixNewPasswordInput';
import AsterixErrorAlert from '@/components/molecules/shared/AsterixErrorAlert';
import AsterixInput from '@/components/atoms/AsterixInput';
import { Toast } from '@/model/shared/Toast';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import { isCustomDomain, customImgTypes } from '@/utils/domain';
import CustomBrandImg from '@/components/atoms/CustomBrandImg.vue';

export default {
  name: 'ResetPassword',
  components: {
    SunMediaLogoDark,
    AsterixNewPasswordInput,
    AsterixErrorAlert,
    AsterixInput,
    CustomBrandImg,
  },
  mixins: [queryParamsMixin],
  data() {
    return {
      api: null,
      isLoading: false,
      formErrors: {
        email: null,
        password: null,
      },
      i18nErrors: ERRORS.en,
      i18nSuccess: SUCCESS.en,
      year: YEAR,
      isResetPassword: false,
      newPassword: null,
      token: null,
      showErrorAlert: false,
      errorMessage: '',
      customDomain: isCustomDomain(),
      customImgTypes,
    };
  },
  beforeMount() {
    if (this.queryParams?.token) {
      this.isResetPassword = true;
      this.token = this.queryParams.token;
    }
  },
  methods: {
    ...mapActions({
      createToast: CREATE_TOAST,
    }),
    sendForm({ form, valid }) {
      this.showSuccessAlert = false;
      this.showErrorAlert = false;
      if (valid) {
        if (this.isResetPassword) this.passwordReset();
        else this.sendEmailResetPassword(form);
      }
    },
    async sendEmailResetPassword(form) {
      const email = form.email.value;
      this.isLoading = true;
      try {
        await resetPasswordAndSendEmail(email);
        await this.$router.push({
          name: 'login',
          params: {
            showSuccessAlert: true,
            successMessage: this.i18nSuccess.sendEmail,
          },
        });
      } catch (e) {
        this.showErrorAlert = true;
        if (e.message === `The user email '${form.email.value}' not exists.`) {
          this.errorMessage = this.i18nErrors.emailNotExists;
        } else if (e.message === 'The token reset password is still valid.') {
          this.errorMessage = this.i18nErrors.emailResetRequested;
        } else {
          this.errorMessage = this.i18nErrors.errorHasOccurred;
          this.createToast(Toast.error('Error', e.message));
        }
      } finally {
        this.isLoading = false;
      }
    },
    async passwordReset() {
      this.isLoading = true;
      try {
        await resetPassword(this.newPassword, this.token);
        await this.$router.push({
          name: 'login',
          params: {
            showSuccessAlert: true,
            successMessage: this.i18nSuccess.resetPassword,
          },
        });
      } catch (e) {
        this.showErrorAlert = true;
        if (e.message === 'NOT VALID REFRESH TOKEN') {
          this.errorMessage = this.i18nErrors.tokenExpired;
        } else if (e.message === 'The token reset password not exists.') {
          this.errorMessage = this.i18nErrors.tokenUsed;
        } else {
          this.errorMessage = this.i18nErrors.errorHasOccurred;
          this.createToast(Toast.error('Error', e.message));
        }
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
.custom-brand {
  max-height: 100px;
}
@media (min-width: 640px) {
  .inner-reset,
  .img-reset {
    min-width: 300px;
  }
  .bg-gray-custom {
    background-color: #dbd7d8;
  }
}
</style>
